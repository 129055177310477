/*===========================================
	VARIAVEIS
============================================*/
@import 'variaveis';
/*===========================================
	BASE
============================================*/
@import 'base';
/*===========================================
	HEADER
============================================*/
@import 'header';
/*===========================================
	CONTENT
============================================*/
@import 'content';
/*===========================================
	FOOTER
============================================*/
@import 'footer';
/*===========================================
	VENDOR
============================================*/

@import 'vendor/alertify.core';
@import 'vendor/alertify.default';
@import 'vendor/slick-theme';
@import 'vendor/slick';
/*===========================================
	RESPONSIVO
============================================*/
@import 'mobile';
