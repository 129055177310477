@charset "UTF-8";
/*===========================================
	VARIAVEIS
============================================*/
/* Cor padrão branca*/
/* Cor Padrão Preta - Tom de Preto com base no Layout */
/* Cor primária do site */
/* Cor da seta dos Sliders */
/* BG que envolve as setas */
/* Cor do Texto do botão de oferta e oferta menores */
/* Fonte padrão de todo o Site */
@font-face {
  font-family: nouvel-renault;
  src: url(../fonts/NouvelR-Regular.ttf);
}
/*===========================================
	BASE
============================================*/
body {
  font-size: 12px;
  font-weight: 400;
  font-family: "nouvel-renault";
  color: #1b1b1b;
}

h1, h2, h3, h4, h5 {
  margin: 0px;
  font-weight: inherit;
}

ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
a:focus {
  outline: none;
  box-shadow: none;
}
a:active {
  text-decoration: none;
  color: inherit;
}

/*===========================================
	HEADER
============================================*/
header {
  background: #ffcc33;
  box-shadow: 0px 1px 3.92px 0.08px rgba(0, 0, 0, 0.3);
  padding-top: 20px;
  padding-bottom: 20px;
}

.logo {
  font-size: inherit;
}

.atendimento {
  display: inline-block;
}
.atendimento li {
  display: inline-block;
  padding-right: 25px;
  padding-left: 25px;
  border-right: solid 1px #000;
}
.atendimento li .icone-atendiento {
  display: inline-block;
  font-size: 20px;
  margin-right: 5px;
  margin-top: 5px;
  vertical-align: top;
  color: #000;
}
.atendimento li .numero-atendimento {
  display: inline-block;
}
.atendimento li .numero-atendimento span {
  display: block;
  font-size: 14px;
  color: #000;
}
.atendimento li .numero-atendimento strong {
  display: block;
  font-size: 14px;
  font-weight: 800;
  color: #000;
}
.atendimento li:last-child {
  padding-right: 0;
  border-right: none;
}

.menu-top {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sub-header {
  padding: 20px 0px;
}

.nome-duas-cores {
  margin-bottom: 0;
}

.uma-cor, .duas-cor {
  color: #1b1b1b;
  font-size: 32px;
  font-style: italic;
}

.servicos-da-loja {
  margin-top: -12px;
}

.uma-cor {
  font-weight: 700;
}

.duas-cor {
  font-weight: normal;
}

.menu li {
  display: inline-block;
  padding-right: 25px;
  position: relative;
  padding: 10px;
}
.menu li a {
  text-transform: uppercase;
  font-weight: 300;
}
.menu li a:hover {
  text-decoration: underline;
}
.menu li .sub-menu {
  position: absolute;
  top: 32px;
  left: 0;
  background: #252525;
  z-index: 200;
  display: none;
}
.menu li .sub-menu li {
  width: 250px;
  margin-right: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  padding-left: 30px;
  padding-bottom: 0;
  padding-right: 0;
}
.menu li .sub-menu li a {
  display: block;
  color: #fff;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: solid 1px #fff;
}
.menu li .sub-menu li:hover {
  background: #ffcc33;
}
.menu li .sub-menu li:hover a {
  border-color: #ffcc33;
  text-decoration: none;
}
.menu li .sub-menu li:last-child a {
  border-bottom: none;
}
.menu li:hover .sub-menu {
  display: block;
}

.icones-leads {
  text-align: right;
  margin-top: 35px;
}
.icones-leads span {
  margin-left: 30px;
  margin-right: 30px;
  cursor: pointer;
}
.icones-leads span i {
  font-size: 25px;
}
.icones-leads span .fa-mobile {
  font-size: 30px;
  vertical-align: top;
}

/*===========================================
	CONTENT
============================================*/
.mega-box-slider {
  position: relative;
  width: 100%;
  height: auto;
}

.slider-principal.--mobile {
  display: block;
}
.slider-principal.--desktop {
  display: none;
}
.slider-principal .container {
  padding: 0px;
}
.slider-principal .slick-slide:focus {
  outline: none;
}
.slider-principal .slick-slide img {
  max-width: 100%;
}
.slider-principal .slick-dots {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: 20px;
}
.slider-principal .slick-dots li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  text-indent: -9999px;
  background: #7f7f7f;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.slider-principal .slick-dots li.slick-active {
  background: #ffcc33;
}

.seta-direita,
.seta-outro-direita,
.seta-outro-esquerda,
.seta-esquerda,
.seta-slider-venda-direta-esquerda,
.seta-slider-venda-direta-direita,
.seta-slider-de-carros-esquerda,
.seta-slider-de-carros-direita,
.seta-slider-simule-esquerda,
.seta-slider-simule-direita,
.seta-peca-direita,
.seta-peca-esquerda,
.seta-semi-esquerda,
.seta-semi-direita,
.seta-adicionais-direita,
.seta-adicionais-esquerda {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #ffcc33;
  color: #322f26;
  z-index: 999;
  position: absolute;
  cursor: pointer;
  top: 40%;
}
.seta-direita i,
.seta-outro-direita i,
.seta-outro-esquerda i,
.seta-esquerda i,
.seta-slider-venda-direta-esquerda i,
.seta-slider-venda-direta-direita i,
.seta-slider-de-carros-esquerda i,
.seta-slider-de-carros-direita i,
.seta-slider-simule-esquerda i,
.seta-slider-simule-direita i,
.seta-peca-direita i,
.seta-peca-esquerda i,
.seta-semi-esquerda i,
.seta-semi-direita i,
.seta-adicionais-direita i,
.seta-adicionais-esquerda i {
  font-size: 40px;
  color: #282722;
}

.seta-direita {
  right: 0;
  z-index: 1000;
}

.seta-esquerda {
  left: 0;
  z-index: 1000;
}

.seta-semi-esquerda,
.seta-semi-direita {
  top: 55%;
}

.seta-slider-venda-direta-esquerda {
  left: -50px;
  top: 53%;
}

.seta-slider-venda-direta-direita {
  right: -50px;
  top: 53%;
}

.seta-slider-de-carros-esquerda {
  left: 0px;
}

.seta-slider-de-carros-direita {
  right: 0px;
}

.seta-slider-de-carros-esquerda,
.seta-slider-de-carros-direita {
  top: 53%;
}

.seta-peca-esquerda,
.seta-semi-esquerda,
.seta-slider-simule-esquerda {
  left: 15%;
}

.seta-peca-direita,
.seta-semi-direita,
.seta-slider-simule-direita {
  right: 15%;
}

.seta-slider-simule-esquerda,
.seta-slider-simule-direita {
  top: 40%;
}

.item-slider {
  background-position: center center;
  background-size: cover;
}

.box-interessado span {
  display: block;
  font-weight: 900;
  font-size: 48px;
  margin: 0;
  text-transform: uppercase;
  color: #fff;
}
.box-interessado span.pequeno-texto-slider {
  font-size: 16px;
  margin-bottom: -10px;
}
.box-interessado span.preco-do-carro-slider {
  font-weight: 300;
  margin-top: -20px;
  margin-bottom: 20px;
}

.estou-interessado {
  display: inline-block;
  background-color: #62ddbe;
  box-shadow: inset 0px 3px 0px 0px rgba(179, 255, 255, 0.75);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 15px;
  text-transform: uppercase;
  font-size: 23px;
  font-weight: 900;
  color: #fff;
}
.estou-interessado i {
  color: #fff;
  margin-left: 10px;
}
.estou-interessado:hover {
  color: #fff;
}

.box {
  margin-top: 50px;
}

.box-escolha-um-carro {
  padding-top: 65px;
  padding-bottom: 65px;
  position: relative;
}

.box-titulo-e-sub-titulo {
  margin-bottom: 30px;
}
.box-titulo-e-sub-titulo h3 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 900;
  color: #1b1b1b;
}
.box-titulo-e-sub-titulo h1 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 300;
  color: #1b1b1b;
}

.box-filtro span {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 20px;
  display: block;
}

.filtro {
  display: inline-block;
  border: solid 1px #e5e5e5;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 70px;
}
.filtro li {
  display: inline-block;
  padding-left: 25px;
  padding-right: 25px;
  border-right: solid 1px #7e7e7e;
}
.filtro li a {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
  color: #636363;
}
.filtro li:last-child {
  border-right: none;
}
.filtro li.filtro-ativo a {
  font-weight: 400;
}

.slider-de-carros,
.slider-simule,
.slider-peca,
.slider-seminovos {
  height: 300px;
}
.slider-de-carros .slick-dots,
.slider-simule .slick-dots,
.slider-peca .slick-dots,
.slider-seminovos .slick-dots {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: 20px;
}
.slider-de-carros .slick-dots li,
.slider-simule .slick-dots li,
.slider-peca .slick-dots li,
.slider-seminovos .slick-dots li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  text-indent: -9999px;
  background: #7f7f7f;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.slider-de-carros .slick-dots li.slick-active,
.slider-simule .slick-dots li.slick-active,
.slider-peca .slick-dots li.slick-active,
.slider-seminovos .slick-dots li.slick-active {
  background: #ffcc33;
}

.item-carros,
.item-interno-carros {
  vertical-align: top;
  display: inline-block;
  width: 280px;
  margin-right: 10px;
  margin-left: 10px;
  border: solid 1px #eeeeee;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  height: 230px;
  overflow: hidden;
}
.item-carros img,
.item-interno-carros img {
  width: 100%;
  max-height: 155px;
  text-align: center;
}

.item-interno-carros {
  margin-right: 0px;
  margin-left: 0;
}
.item-interno-carros .a-partir {
  margin-bottom: 5px;
}
.item-interno-carros .confira-oferta {
  margin-top: 10px;
}

.carros-novos {
  margin-bottom: 50px;
}

.slider-simule .item-carros {
  vertical-align: top;
  display: inline-block;
  width: 280px;
  border: solid 1px #eeeeee;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  height: 180px;
  overflow: hidden;
}

.slider-seminovos .item-carros {
  background: #fff;
}

.cor-semi .box-titulo-e-sub-titulo h3,
.cor-semi .box-titulo-e-sub-titulo h1 {
  color: #fff;
}

.nome-do-carro {
  display: block;
  font-size: 18px;
  font-weight: 900;
  color: #636363;
  text-transform: uppercase;
}

.a-partir {
  display: block;
  font-size: 12px;
  font-weight: 700;
  color: #636363;
  margin-bottom: 20px;
}

.confira-oferta {
  display: inline-block;
  color: #fff;
  background: #1b1b1b;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 25px;
  padding-right: 25px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.confira-oferta:hover {
  background: #ffcc33;
  color: #fff;
}

.item-carros:hover {
  height: 300px;
}

.simule-cor {
  background: #fafafa;
}

.no-margin {
  margin: 0;
}

.escolha-outro {
  margin-top: 30px;
  display: inline-block;
  background: #ffcc33;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 75px;
  padding-right: 55px;
  text-transform: uppercase;
  font-weight: 900;
  color: #fff;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.escolha-outro i {
  margin-left: 20px;
}
.escolha-outro:hover {
  background: #bb3c2b;
  color: #fff;
}

.cor-semi {
  background: #1b1b1b;
}

.procurar-semi-novo {
  margin-bottom: 65px;
}
.procurar-semi-novo input {
  background: none;
  border: solid 1px #fff;
  height: 80px;
  color: #fff;
  border-right: none;
}
.procurar-semi-novo input:focus {
  outline: none;
}
.procurar-semi-novo .input-group-addon {
  background: none;
  border-color: #fff;
}
.procurar-semi-novo .input-group-addon i {
  color: #fff;
  font-size: 20px;
}

.venda-direta .box-titulo-e-sub-titulo {
  margin-top: 20px;
}

.container-slider-venda-direta {
  position: relative;
}

.container-cards {
  display: flex;
  flex-wrap: wrap;
}
.container-cards .card-itens {
  margin-bottom: 10px;
}
.container-cards .card-itens h4 {
  margin-top: 10px;
  text-transform: uppercase;
}
.container-cards .card-itens p {
  font-size: 1.3rem;
}

.box-atendimento-pos {
  padding-top: 85px;
  padding-bottom: 85px;
  background-size: cover;
  background-position: center center;
}
.box-atendimento-pos .box-titulo-e-sub-titulo h3,
.box-atendimento-pos .box-titulo-e-sub-titulo h1 {
  color: #fff;
}

.botao-pos-venda {
  display: inline-block;
  border-bottom: solid 5px transparent;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  position: relative;
  -moz-border-radius: 16px;
  -webkit-border-radius: 16px;
  border-radius: 16px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.botao-pos-venda img {
  vertical-align: top;
  margin-right: 25px;
}
.botao-pos-venda .texto-do-botao {
  display: inline-block;
  margin-top: 10px;
}
.botao-pos-venda .texto-do-botao span {
  display: block;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: -8px;
}
.botao-pos-venda .texto-do-botao span strong {
  font-weight: 900;
}
.botao-pos-venda i {
  position: absolute;
  right: 25px;
  color: #fff;
  top: 30%;
  font-size: 30px;
}
.botao-pos-venda.pos-whats {
  background: #35a648;
  border-color: #40c957;
}
.botao-pos-venda.pos-whats:hover {
  background: #40c957;
  border-color: #35a648;
}
.botao-pos-venda.pos-pecas {
  background: #228bab;
  border-color: #36a9cc;
}
.botao-pos-venda.pos-pecas:hover {
  background: #36a9cc;
  border-color: #228bab;
}
.botao-pos-venda.pos-revisao {
  background: #b45545;
  border-color: #ff7c66;
}
.botao-pos-venda.pos-revisao:hover {
  background: #ff7c66;
  border-color: #b45545;
}

.renault-atendimento .box-titulo-e-sub-titulo h3,
.renault-atendimento .box-titulo-e-sub-titulo h1 {
  color: #1b1b1b;
}
.renault-atendimento .botao-pos-venda {
  background: #252525;
  border-color: #636363;
  padding-top: 10px;
  padding-bottom: 10px;
}
.renault-atendimento .botao-pos-venda:hover {
  background: #636363;
  border-color: #252525;
}

.pequeno-texto {
  text-align: center;
  margin-bottom: 50px;
}
.pequeno-texto p {
  font-size: 18px;
  font-weight: 300;
}

.box-mini-mapa {
  background: #1b1b1b;
  padding-top: 15px;
  padding-bottom: 15px;
}
.box-mini-mapa span {
  color: #fff;
  text-transform: uppercase;
}
.box-mini-mapa span a {
  font-weight: 700;
}

.nome-da-categoria {
  padding-bottom: 15px;
  border-bottom: solid 1px #e5e5e5;
  margin-bottom: 20px;
}
.nome-da-categoria span {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  color: #636363;
}

.sobre-pagina {
  border: solid 1px #e5e5e5;
  display: inline-block;
  padding-left: 95px;
  padding-right: 95px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
  margin-top: -80px;
  background: #fff;
  width: 75%;
}
.sobre-pagina .box-titulo-e-sub-titulo {
  margin-bottom: 50px;
}
.sobre-pagina .pequeno-texto {
  margin-bottom: 30px;
  text-align: left;
}
.sobre-pagina .botao-pos-venda,
.sobre-pagina .pos-whats {
  padding-top: 10px;
  margin-top: 50px;
}

.box-imagem-interna {
  height: 380px;
}
.box-imagem-interna img {
  width: 100%;
  max-width: 1180px;
}

.box-fotos-empresa {
  background: #fafafa;
  padding-top: 55px;
  padding-bottom: 55px;
  border-top: solid 1px #e5e5e5;
  border-bottom: solid 1px #e5e5e5;
  margin-top: 50px;
}

.item-galeria {
  width: 100%;
}

.item-pager-galeria {
  display: inline-block;
  margin-right: -1px;
}

.pager-galeria {
  margin-bottom: 50px;
}

.ligamos-pra-voce {
  text-align: center;
  padding-bottom: 22px;
  border-color: #252525;
  background: #636363;
  padding-top: 10px;
}
.ligamos-pra-voce img {
  position: absolute;
  left: 30px;
}
.ligamos-pra-voce .texto-do-botao {
  margin-top: 15px;
}
.ligamos-pra-voce .texto-do-botao span {
  display: inline-block;
}
.ligamos-pra-voce:hover {
  background: #252525;
  border-color: #636363;
}

.icone-telefone {
  display: inline-block;
  margin-bottom: 40px;
  vertical-align: top;
  margin-top: 10px;
}
.icone-telefone i {
  font-size: 20px;
}

.caixa-numero {
  display: inline-block;
  margin-left: 15px;
}
.caixa-numero span {
  display: block;
  font-size: 14px;
}

.contato {
  margin-bottom: 50px;
}
.contato textarea,
.contato input {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin-bottom: 15px;
}

.botao-e-informacao {
  margin-bottom: 20px;
}

.botao-mais-infos {
  width: 100%;
  position: relative;
  background: #eeeeee;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  cursor: pointer;
}
.botao-mais-infos span {
  font-size: 16px;
  font-weight: 300;
}
.botao-mais-infos .fa-plus {
  position: absolute;
  right: 15px;
  top: 18px;
  font-size: 18px;
  opacity: 1;
}
.botao-mais-infos .fa-minus {
  position: absolute;
  right: 15px;
  top: 18px;
  font-size: 18px;
  opacity: 0;
}

.box-mais-info {
  background: #eeeeee;
  padding-top: 35px;
  padding-bottom: 35px;
}

.texto-mais-info {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
}

.botoes-menores {
  display: inline-block;
  color: #fff;
  background: #ffcc33;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.botoes-menores:hover {
  color: #fff;
  background: #ffcc33;
}

.menor-ativo {
  background: #ffcc33;
  margin-left: 30px;
}

.mais-ativo {
  background: #636363;
  color: #fff;
}
.mais-ativo .fa-plus {
  opacity: 0;
}
.mais-ativo .fa-minus {
  opacity: 1;
}

.box-escolha-carro {
  position: relative;
  /*margin-top: 100px;*/
  margin-bottom: 100px;
}
.box-escolha-carro .seta-slider-simule-esquerda,
.box-escolha-carro .seta-slider-simule-direita {
  top: 20%;
}

.interna-consorcio {
  margin-top: 0;
  padding-top: 40px;
  background: #fafafa;
}

.card-escolha-versao {
  padding: 50px;
  background: #fff;
  border: solid 1px #e5e5e5;
}

.nome-e-ano {
  font-size: 48px;
  display: block;
}
.nome-e-ano strong {
  font-weight: 900;
}

.versao-do-carro {
  font-size: 18px;
  display: block;
  margin-bottom: 25px;
}
.versao-do-carro strong {
  font-weight: 900;
}

.texto-legal {
  font-size: 12px;
  color: #636363;
}

.imagem-do-carro {
  text-align: center;
  margin-bottom: 30px;
}
.imagem-do-carro img {
  /*		width: 100%;
  max-width: 225px;*/
}

.card-escolha-versao .imagem-do-carro img {
  width: 100%;
  display: inline-block;
  max-width: 280px;
}

.escolha-versao {
  height: 35px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 25px;
  font-size: 14px;
}

.box-de-preco-da-versao .parcelas-consorcio {
  display: block;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  color: #636363;
}
.box-de-preco-da-versao .valor-consorcio {
  display: block;
  text-align: center;
  text-transform: uppercase;
  color: #636363;
  font-size: 30px;
  font-weight: 300;
}
.box-de-preco-da-versao .valor-consorcio em {
  font-style: normal;
  text-transform: none;
  font-size: 14px;
}
.box-de-preco-da-versao .credito-consorcio {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #636363;
  margin-top: 25px;
}
.box-de-preco-da-versao .valor-do-credito-consorcio {
  text-align: center;
  font-size: 18px;
  color: #636363;
  display: block;
}
.box-de-preco-da-versao .valor-do-credito-consorcio strong {
  font-weight: 900;
}

.card-plano-consorcio {
  padding-top: 35px;
  padding-bottom: 12px;
  background: #fff;
  border: solid 1px #e5e5e5;
  margin-bottom: 20px;
}
.card-plano-consorcio .tempo-do-plano,
.card-plano-consorcio .contemplados {
  color: #252525;
  font-size: 14px;
  display: block;
  margin-bottom: 20px;
}
.card-plano-consorcio .valor-do-plano {
  color: #252525;
  display: block;
  font-size: 24px;
  margin-bottom: 20px;
}
.card-plano-consorcio .valor-do-plano strong {
  font-weight: 900;
}

.titulo-escolha-um-plano {
  display: inherit;
  margin-top: 60px;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 50px;
}

.card-form-financiamento {
  padding: 50px;
  background: #fff;
  border: solid 1px #e5e5e5;
  margin-bottom: 20px;
}
.card-form-financiamento h1 {
  display: inherit;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 20px;
}

.custom-modal {
  display: inline-block;
  background: #ffcc33;
  padding-top: 45px;
  padding-left: 100px;
  padding-right: 100px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  border-top-right-radius: 0;
  position: relative;
  padding-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .custom-modal {
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

#modal-whats {
  height: 400px !important;
}

#box-modal-floater {
  padding: 30px 0px 0px 0px;
  background: white;
}
#box-modal-floater .tag-preencher span {
  color: black;
}

.revisao-modal {
  max-width: 650px;
}

.tag-preencher {
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
}
.tag-preencher span {
  text-transform: uppercase;
  font-weight: 300;
  color: #333;
  font-size: 20px;
  display: block;
  margin-bottom: 10px;
}
.tag-preencher i {
  color: #333;
  font-size: 40px;
}

.modal-contato label {
  color: #333;
}
.modal-contato input,
.modal-contato textarea,
.modal-contato select {
  color: #333;
  border: none;
  border-bottom: solid 1px #333;
  height: 40px;
  background: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
}
.modal-contato input:focus,
.modal-contato textarea:focus,
.modal-contato select:focus {
  border: solid 1px #333;
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.075), 0 0 8px rgba(255, 255, 255, 0.6);
}
.modal-contato input[disabled], .modal-contato input[readonly],
.modal-contato textarea[disabled],
.modal-contato textarea[readonly],
.modal-contato select[disabled],
.modal-contato select[readonly] {
  background-color: #ffbf00;
}
.modal-contato select {
  padding: 6px;
}
.modal-contato select option {
  color: #1b1b1b;
}
.modal-contato textarea {
  min-height: 80px;
}
.modal-contato ::-webkit-input-placeholder {
  color: #333;
}
.modal-contato :-moz-placeholder {
  /* Firefox 18- */
  color: #333;
}
.modal-contato ::-moz-placeholder {
  /* Firefox 19+ */
  color: #333;
}
.modal-contato :-ms-input-placeholder {
  color: #333;
}
.modal-contato span {
  color: #333;
  text-align: center;
  font-size: 12px;
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;
}
.modal-contato .enviar-modal {
  background: #313131;
  color: #fff;
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  border: none;
  padding-top: 20px;
  padding-bottom: 20px;
}
.modal-contato .enviar-modal i {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 30px;
  color: #333;
}

.fechar-modal {
  width: 60px;
  right: 60px;
  position: absolute;
  right: 0;
  top: 0;
  background: #313131;
  text-align: center;
  line-height: 60px;
  z-index: 100;
}
.fechar-modal i {
  color: #fff;
  font-size: 30px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.fechar-modal:hover i {
  color: #ffcc33;
}

.box-quadro-de-pecas {
  background: #fafafa;
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: solid 1px #ededed;
  border-bottom: solid 1px #ededed;
}

.titulo-box-filtro-pecas {
  background: #333333;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  border-bottom: solid 1px #636363;
}
.titulo-box-filtro-pecas span {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
}

.pesquisa-filtro {
  background: #252525;
  border-bottom: solid 1px #636363;
  padding: 10px;
}
.pesquisa-filtro .input-group,
.pesquisa-filtro .form-group {
  width: 100%;
}
.pesquisa-filtro input {
  border: solid 1px #fff;
  height: 40px;
  background: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
  border-right: none;
}
.pesquisa-filtro input:focus {
  border: solid 1px #fff;
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.075), 0 0 8px rgba(255, 255, 255, 0.6);
}
.pesquisa-filtro ::-webkit-input-placeholder {
  color: #fff;
}
.pesquisa-filtro :-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}
.pesquisa-filtro ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}
.pesquisa-filtro :-ms-input-placeholder {
  color: #fff;
}
.pesquisa-filtro .input-group-addon {
  background: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-left: none;
  border-color: #fff;
}
.pesquisa-filtro .input-group-addon i {
  color: #fff;
}

.clique-carro {
  display: block;
  background: #1b1b1b;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-bottom: solid 1px #636363;
  padding-left: 10px;
  padding-right: 10px;
}
.clique-carro span {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;
}
.clique-carro:hover {
  background: #ffcc33;
}
.clique-carro i {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
  vertical-align: middle;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.clique-carro.feita-escolha {
  background: #ffcc33;
  border-color: #ffcc33;
}
.clique-carro.feita-escolha span {
  font-weight: 700;
}
.clique-carro.feita-escolha i {
  opacity: 1;
}
.clique-carro.feita-escolha .fa-times {
  position: absolute;
  right: 0;
  top: 25px;
}

.barra-ordenar {
  background: #e3e3e3;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 20px;
}
.barra-ordenar span {
  text-transform: uppercase;
  color: #0f0f0f;
}
.barra-ordenar .nome-da-barra {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  color: #1b1b1b;
}

.ordenar {
  display: inline-block;
  margin-left: 10px;
}
.ordenar li {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  border-right: solid 1px #0f0f0f;
}
.ordenar li a {
  text-transform: uppercase;
  color: #0f0f0f;
}
.ordenar li:last-child {
  border-right: none;
}

.caixa-de-resultados .item-carros {
  width: 265px;
  margin-left: 7px;
  margin-bottom: 30px;
  margin-right: 7px;
}

.botoes-internos-carro {
  background: #fafafa;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #e5e5e5;
  margin-bottom: 75px;
}

.box-versoes-com-mais {
  padding-bottom: 65px;
}

.box-fotos-do-carro {
  background: #fafafa;
  border-top: solid 1px #e5e5e5;
  padding-top: 30px;
  padding-bottom: 65px;
}

.interior-exterior {
  margin-bottom: 30px;
  position: relative;
}

.interior,
.exterior {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  background: #636363;
  color: #e5e5e5;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
  margin-right: 20px;
  padding-left: 65px;
  padding-right: 65px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.interior:hover,
.exterior:hover {
  background: #ffcc33;
  color: #fff;
}
.interior.ativo-in-ex,
.exterior.ativo-in-ex {
  background: #ffcc33;
  color: #fff;
}

.ativo-in-ex:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -15px;
  left: 12px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #ffcc33;
}

.cards-adicionais {
  margin-bottom: 100px;
  position: relative;
}
.cards-adicionais .slick-slide:focus {
  outline: none;
}

.card-adicional {
  margin-right: 10px;
  margin-left: 10px;
  width: 290px;
  padding-top: 30px;
  text-align: center;
  display: inline-block;
  border: solid 1px #eeeeee;
  opacity: 0.5;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.card-adicional .confira-oferta {
  margin-bottom: 20px;
}
.card-adicional:hover {
  opacity: 1;
}
.card-adicional.adicional-ativo {
  opacity: 1;
}

.nome-do-carro-adicional,
.modelo-do-carro-adicional,
.preco-do-carro-adicional {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
}
.nome-do-carro-adicional strong,
.modelo-do-carro-adicional strong,
.preco-do-carro-adicional strong {
  font-weight: 900;
}

.modelo-do-carro-adicional {
  font-size: 18px;
}

.preco-do-carro-adicional {
  font-size: 16px;
  margin-bottom: 20px;
}

.thumb-foto-adicional {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.thumb-foto-adicional img {
  display: inline-block !important;
  width: 100%;
  max-width: 185px;
}

.adicionais-do-carro {
  display: none;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #1b1b1b;
}
.adicionais-do-carro li {
  color: #fff;
}

.ver-itens-adicional {
  display: inline-block;
  width: 100%;
  background: #f2f2f2;
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-weight: 900;
  color: #929292;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.ver-itens-adicional:hover {
  color: #252525;
}

.seta-adicionais-direita {
  right: -5%;
  border: solid 1px #252525;
  background: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.seta-adicionais-direita i {
  color: #252525;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.seta-adicionais-direita:hover {
  background: #ffcc33;
  border-color: #ffcc33;
}
.seta-adicionais-direita:hover i {
  color: #fff;
}

.seta-adicionais-esquerda {
  left: -5%;
  border: solid 1px #252525;
  background: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.seta-adicionais-esquerda i {
  color: #252525;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.seta-adicionais-esquerda:hover {
  background: #ffcc33;
  border-color: #ffcc33;
}
.seta-adicionais-esquerda:hover i {
  color: #fff;
}

.seta-versao-esquerda,
.seta-ultimo-esquerda,
.seta-ultimo-direita,
.seta-versao-direita {
  display: inline-block;
  position: absolute;
  text-transform: uppercase;
  font-size: 14px;
  top: -50px;
  color: #828282;
  font-weight: 900;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  vertical-align: top;
  cursor: pointer;
}
.seta-versao-esquerda i,
.seta-ultimo-esquerda i,
.seta-ultimo-direita i,
.seta-versao-direita i {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: solid 1px #252525;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #252525;
  text-align: center;
  line-height: 40px;
  margin-right: 15px;
  font-size: 24px;
}
.seta-versao-esquerda:hover,
.seta-ultimo-esquerda:hover,
.seta-ultimo-direita:hover,
.seta-versao-direita:hover {
  color: #252525;
}
.seta-versao-esquerda:hover i,
.seta-ultimo-esquerda:hover i,
.seta-ultimo-direita:hover i,
.seta-versao-direita:hover i {
  background: #ffcc33;
  color: #fff;
  border-color: #ffcc33;
}

.seta-versao-esquerda {
  left: 0;
}

.seta-versao-direita {
  right: 0;
}
.seta-versao-direita i {
  margin-left: 10px;
  margin-right: 0;
}

.item-outro-slider {
  display: inline-block;
  width: 280px;
  background: #fff;
  height: 450px;
  margin-left: 10px;
  margin-right: 10px;
}

.thumb-imagem-outro {
  margin-bottom: 25px;
}
.thumb-imagem-outro img {
  width: 100%;
}

.outro-titulo {
  display: inline-block;
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  color: #636363;
  margin-bottom: 30px;
  text-align: center;
}

.outro-texto {
  padding-left: 15px;
  padding-right: 15px;
}
.outro-texto p {
  font-weight: 300;
  font-size: 12px;
  color: #636363;
}

.outro-slider .slick-dots {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: 50px;
}
.outro-slider .slick-dots li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  text-indent: -9999px;
  background: #7f7f7f;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.outro-slider .slick-dots li.slick-active {
  background: #ffcc33;
}

.seta-outro-direita {
  right: 0px;
}

.seta-outro-esquerda {
  left: 0px;
}

.box-escolha-um-carro .item-versao .sobre-pagina {
  margin-top: 0;
}
.box-escolha-um-carro .item-versao .sobre-pagina .card-adicional {
  opacity: 1;
  border: none;
}

.box-escolha-um-carro .setas-versoes .seta-ultimo-direita,
.box-escolha-um-carro .setas-versoes .seta-ultimo-esquerda {
  top: 40%;
  z-index: 100;
  background: #fff;
}
.box-escolha-um-carro .setas-versoes .seta-ultimo-direita {
  right: -25px;
}
.box-escolha-um-carro .setas-versoes .seta-ultimo-esquerda {
  left: -25px;
}

.item-pager-galeria {
  cursor: pointer;
}

.galeria-fotos {
  width: 100%;
}
@media screen and (max-width: 425px) {
  .galeria-fotos {
    height: 135px;
  }
}
.galeria-fotos picture {
  float: left;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 16.666%;
  height: 33.333%;
}
.galeria-fotos picture:first-child {
  width: 50%;
  height: 100%;
}

.fotos-interior .galeria-fotos .slick-list .slick-track {
  width: 100% !important;
}
.fotos-interior .galeria-fotos .slick-list .slick-track .item-galeria {
  width: 100% !important;
}

.slick-dots li.slick-active button:before {
  content: none !important;
}

.slick-dots li button:before {
  content: none !important;
}

.container-modal-telefones-item {
  border: solid 1px #333;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  position: relative;
}
.container-modal-telefones-item a,
.container-modal-telefones-item span {
  font-size: 1rem;
}
.container-modal-telefones-item a {
  position: absolute;
  right: 0;
  left: 0;
  text-align: right;
}

.blocker {
  z-index: 2000 !important;
}

/*===========================================
	FOOTER
============================================*/
.whatsapp-fixed {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  z-index: 10000000;
}

.horario-de-funcionamento {
  padding-top: 45px;
  padding-bottom: 40px;
}
.horario-de-funcionamento .nome-da-loja {
  display: inline-block;
  margin-top: 20px;
}

.logo-da-empresa {
  display: inline-block;
  margin-right: 30px;
  vertical-align: top;
  margin-top: 20px;
}

.horarios-e-mapa {
  width: 100%;
  text-align: right;
  padding-top: 35px;
  padding-bottom: 35px;
  border-left: solid 1px #e3e3e3;
}

.horario,
.mapa {
  display: inline-block;
  text-align: left;
}

.horario {
  margin-top: 10px;
}

.icone {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.icone i {
  color: #ffcc33;
  font-size: 24px;
  margin-top: 5px;
}

.ver-google-maps {
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  margin-top: 10px;
  text-transform: uppercase;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.ver-google-maps i {
  vertical-align: top;
  font-size: 18px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-top: -1px;
}
.ver-google-maps:hover i {
  margin-left: 10px;
}

footer {
  padding-top: 60px;
  padding-bottom: 30px;
  background: #1b1b1b;
}
footer .atendimento li {
  padding: 0;
  display: block;
  margin-bottom: 30px;
  color: #e5e5e5;
}
footer .atendimento li:last-child {
  padding: 0;
}

.texto-horario {
  display: inline-block;
}
.texto-horario span {
  display: block;
}

.tag-coluna {
  color: #e5e5e5;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 35px;
  display: inline-block;
}

.menu-footer li {
  margin-top: 7px;
  margin-bottom: 7px;
}
.menu-footer li a {
  color: #e5e5e5;
  font-size: 14px;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}
.menu-footer li a:hover {
  color: #ffcc33;
  font-weight: 800;
}

.ms-footer li {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.ms-footer li i {
  color: #1b1b1b;
  background: #636363;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 15px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.ms-footer li i:hover {
  background: #e5e5e5;
}

.ass-lua {
  margin-top: 70px;
  border-top: solid 1px #636363;
  padding-top: 20px;
}

.ass-footer {
  display: block;
  color: #636363;
  font-size: 12px;
}

/*===========================================
	VENDOR
============================================*/
.alertify,
.alertify-show,
.alertify-log {
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); /* easeOutBack */
}

.alertify-hide {
  -webkit-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045); /* easeInBack */
}

.alertify-log-hide {
  -webkit-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045); /* easeInBack */
}

.alertify-cover {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  filter: alpha(opacity=0);
  opacity: 0;
}

.alertify-cover-hidden {
  display: none;
}

.alertify {
  position: fixed;
  z-index: 99999;
  top: 50px;
  left: 50%;
  width: 550px;
  margin-left: -275px;
  opacity: 1;
}

.alertify-hidden {
  -webkit-transform: translate(0, -150px);
  -moz-transform: translate(0, -150px);
  -ms-transform: translate(0, -150px);
  -o-transform: translate(0, -150px);
  transform: translate(0, -150px);
  opacity: 0;
  display: none;
}

/* overwrite display: none; for everything except IE6-8 */
:root * > .alertify-hidden {
  display: block;
  visibility: hidden;
}

.alertify-logs {
  position: fixed;
  z-index: 5000;
  bottom: 10px;
  right: 10px;
  width: 300px;
}

.alertify-logs-hidden {
  display: none;
}

.alertify-log {
  display: block;
  margin-top: 10px;
  position: relative;
  right: -300px;
  opacity: 0;
}

.alertify-log-show {
  right: 0;
  opacity: 1;
}

.alertify-log-hide {
  -webkit-transform: translate(300px, 0);
  -moz-transform: translate(300px, 0);
  -ms-transform: translate(300px, 0);
  -o-transform: translate(300px, 0);
  transform: translate(300px, 0);
  opacity: 0;
}

.alertify-dialog {
  padding: 25px;
}

.alertify-resetFocus {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.alertify-inner {
  text-align: center;
}

.alertify-text {
  margin-bottom: 15px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
}

.alertify-button,
.alertify-button:hover,
.alertify-button:active,
.alertify-button:visited {
  background: none;
  text-decoration: none;
  border: none;
  /* line-height and font-size for input button */
  line-height: 1.5;
  font-size: 100%;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
}

@media only screen and (max-width: 680px) {
  .alertify,
  .alertify-logs {
    width: 90%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .alertify {
    left: 5%;
    margin: 0;
  }
}
/**
 * Default Look and Feel
 */
.alertify,
.alertify-log {
  font-family: sans-serif;
}

.alertify {
  background: #FFF;
  border: 10px solid #333; /* browsers that don't support rgba */
  border: 10px solid rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding; /* Safari 4? Chrome 6? */
  -moz-background-clip: padding; /* Firefox 3.6 */
  background-clip: padding-box; /* Firefox 4, Safari 5, Opera 10, IE 9 */
}

.alertify-text {
  border: 1px solid #CCC;
  padding: 10px;
  border-radius: 4px;
}

.alertify-button {
  /*
  border-radius: 4px;
  color: #FFF;
  font-weight: bold;
  padding: 6px 15px;
  text-decoration: none;
  text-shadow: 1px 1px 0 rgba(0,0,0,.5);
  box-shadow: inset 0 1px 0 0 rgba(255,255,255,.5);
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image:    -moz-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image:     -ms-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image:      -o-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image:         linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));*/
  border: 0;
  height: 40px;
  width: 100%;
  margin-top: 25px;
  color: #fff;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
}

.alertify-button:hover,
.alertify-button:focus {
  outline: none;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background-image: linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.alertify-button:focus {
  box-shadow: 0 0 15px #2B72D5;
}

.alertify-button:active {
  position: relative;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.alertify-button-cancel,
.alertify-button-cancel:hover,
.alertify-button-cancel:focus {
  background-color: #FE1A00;
  border: 1px solid #D83526;
}

.alertify-button-ok,
.alertify-button-ok:hover,
.alertify-button-ok:focus {
  background-color: #f78a2a;
  border: 0;
}

.alertify-log {
  background: #1F1F1F;
  background: rgba(0, 0, 0, 0.9);
  padding: 15px;
  border-radius: 4px;
  color: #FFF;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5);
}

.alertify-log-error {
  background: #FE1A00;
  background: rgba(254, 26, 0, 0.9);
}

.alertify-log-success {
  background: #5CB811;
  background: rgba(92, 184, 17, 0.9);
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./../img/vendor/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 300px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*===========================================
	RESPONSIVO
============================================*/
@media screen and (min-width: 320px) {
  /* 320px */
  .seta-direita,
  .seta-esquerda,
  .seta-slider-de-carros-direita,
  .seta-slider-de-carros-esquerda,
  .seta-slider-venda-direta-esquerda,
  .seta-slider-venda-direta-direita,
  .seta-slider-simule-direita,
  .seta-slider-simule-esquerda,
  .seta-peca-direita,
  .seta-peca-esquerda,
  .seta-outro-esquerda,
  .seta-outro-direita {
    display: none !important;
  }
  .filtro li {
    border: none;
  }
  .botao-pos-venda {
    margin-bottom: 30px;
  }
  .botao-pos-venda i {
    display: none;
  }
  .botao-pos-venda img {
    margin-right: 5px;
  }
  .botao-ver-mais-carros i {
    display: none;
  }
  .menu-footer {
    margin-bottom: 30px;
  }
  .atendimento {
    display: block;
  }
  .ass {
    text-align: center;
    margin-top: 30px;
  }
  .horarios-e-mapa {
    text-align: center;
    border-left: none;
  }
  .horarios-e-mapa .mapa {
    margin-bottom: 20px;
  }
  .logo-da-empresa,
  .horario,
  .nome-da-loja {
    margin-right: 0;
    width: 100%;
    text-align: center;
  }
  footer {
    text-align: center;
  }
  .clique-menu {
    display: inline-block;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
  }
  .clique-menu i {
    margin-left: 5px;
  }
  .box-menu-mobile {
    background: #000;
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 1500;
    left: 0;
    top: 0;
    border-top: 8px solid #fff;
    display: none;
  }
  .clique-fechar {
    text-transform: uppercase;
    font-weight: 900;
    text-align: right;
    width: 100%;
    display: inline-block;
    font-size: 15px;
    color: #fff;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-right: 20px;
  }
  .menu-mobile li {
    text-align: center;
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
  }
  .menu-mobile li a {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 26px;
    color: #fff;
  }
  .menu-mobile li a:hover {
    text-decoration: underline;
  }
  .menu-mobile li .sub-menu {
    background: #252525;
    width: 100%;
    z-index: 9000;
    display: none;
  }
  .menu-mobile li .sub-menu li {
    width: 100%;
    padding-top: 20px;
    text-align: center;
    margin-bottom: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  .menu-mobile li .sub-menu li a {
    display: block;
    width: 100%;
    color: #fff;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: solid 1px #fff;
  }
  .menu-mobile li .sub-menu li:hover {
    background: #ffcc33;
  }
  .menu-mobile li .sub-menu li:hover a {
    border-color: #ffcc33;
    text-decoration: none;
  }
  .menu-mobile li .sub-menu li:last-child a {
    border-bottom: none;
  }
  .box-contato .sobre-pagina {
    padding-left: 30px;
    padding-right: 15px;
    margin-top: -200px;
  }
  .sobre-pagina {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: -200px;
  }
  .menor-ativo {
    margin-left: 0;
  }
}
@media screen and (min-width: 768px) {
  .slider-principal .item-slider {
    height: auto;
    padding-top: 0;
  }
  .slider-principal .item-slider img {
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .slider-principal.--mobile {
    display: none;
  }
  .slider-principal.--desktop {
    display: block;
  }
  .slider-principal .item-slider {
    height: 470px;
    padding-top: 0;
  }
  .slider-principal .item-slider img {
    width: 100%;
  }
  .filtro li {
    border-right: solid 1px #7e7e7e;
  }
  .botao-pos-venda {
    margin-bottom: 30px;
  }
  .botao-pos-venda i {
    display: block;
  }
  .botao-pos-venda img {
    margin-right: 25px;
  }
  .botao-ver-mais-carros i {
    display: inline-block;
  }
  .menu-footer {
    margin-bottom: 0px;
  }
  footer {
    text-align: left;
  }
  .ass {
    text-align: right;
    margin-top: 0px;
  }
  .horarios-e-mapa {
    text-align: right;
    border-left: solid 1px #e3e3e3;
  }
  .horarios-e-mapa .mapa {
    margin-bottom: 0px;
    text-align: left;
  }
  .logo-da-empresa {
    margin-right: 30px;
    width: auto;
    text-align: left;
  }
  .horario,
  .nome-da-loja {
    width: auto;
    text-align: left;
  }
  .seta-direita,
  .seta-esquerda,
  .seta-slider-de-carros-direita,
  .seta-slider-de-carros-esquerda,
  .seta-slider-venda-direta-esquerda,
  .seta-slider-venda-direta-direita,
  .seta-slider-simule-direita,
  .seta-slider-simule-esquerda,
  .seta-peca-direita,
  .seta-peca-esquerda {
    display: none !important;
  }
  .menor-ativo {
    margin-left: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .seta-direita,
  .seta-esquerda,
  .seta-slider-de-carros-direita,
  .seta-slider-de-carros-esquerda,
  .seta-slider-venda-direta-esquerda,
  .seta-slider-venda-direta-direita,
  .seta-slider-simule-direita,
  .seta-slider-simule-esquerda,
  .seta-peca-direita,
  .seta-peca-esquerda,
  .seta-outro-esquerda,
  .seta-outro-direita {
    display: inline-block !important;
  }
  .seta-slider-de-carros-direita {
    right: 1%;
  }
  .seta-slider-de-carros-esquerda {
    left: 1%;
  }
  .box-contato .sobre-pagina {
    padding-left: 95px;
    padding-right: 95px;
    margin-top: -80px;
  }
  .sobre-pagina {
    padding-left: 95px;
    padding-right: 95px;
    margin-top: -80px;
  }
}
@media screen and (min-width: 1400px) {
  .seta-slider-de-carros-direita {
    right: -5%;
  }
  .seta-slider-de-carros-esquerda {
    left: -5%;
  }
}
@media screen and (max-width: 768px) {
  .botao-pos-venda img {
    display: none;
  }
}