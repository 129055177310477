// Cores
$branco: #fff; /* Cor padrão branca*/
$preto: #1b1b1b; /* Cor Padrão Preta - Tom de Preto com base no Layout */


$corMain: #ffcc33; /* Cor primária do site */
$corSeta: #282722; /* Cor da seta dos Sliders */
$bgBotaoConfiraOferta: #ffcc33; /* BG que envolve as setas */
$corTextoBotaoConferirOferta: #fff; /* Cor do Texto do botão de oferta e oferta menores */

// Fonts
$fontmain: 'nouvel-renault'; /* Fonte padrão de todo o Site */


// Mixins
@mixin radius ($radius){
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

@mixin transition ($all){
	-webkit-transition: all ($all) ;
    -moz-transition: all ($all) ;
    -o-transition: all ($all) ;
    transition: all ($all);
}


@mixin box-shadow ($style){
	-moz-box-shadow: ($style);
	-webkit-box-shadow: ($style);
	box-shadow: ($style);
}

@font-face {
  font-family: nouvel-renault;
  src: url(../fonts/NouvelR-Regular.ttf);
}
